/// <reference types="vite/client" />

import { Content, ContentType } from './content';

export async function loadMarkdownFiles(): Promise<Content[]> {
  const articles: Content[] = [];

  try {
    // Use Vite's glob import feature to get all markdown files
    const markdownFiles = import.meta.glob('/public/articles/*.md', { 
      as: 'raw', 
      eager: true 
    });
    console.log({markdownFiles});
    
    for (const [filepath, content] of Object.entries(markdownFiles)) {
      // Extract filename from path (removes '../articles/' and '.md')
      const fileName = filepath.split('/').pop()?.replace('.md', '');
      
      if (fileName) {
        // Extract front matter
        const frontMatter = (content as string).split('---')[1];
        const metadata = parseFrontMatter(frontMatter);
        const articleContent = (content as string).split('---')[2];

        articles.push({
          name: metadata.title || fileName,
          link: `/articles/${fileName}`,
          date: new Date(metadata.date),
          type: ContentType.MARKDOWN,
        });
      }
    }
  } catch (error) {
    console.error('Error loading markdown files:', error);
  }

  return articles;
}

export async function loadMarkdownContent(link: string): Promise<{title: string, content: string, date: Date}> {
  const markdownFiles = import.meta.glob('/public/articles/*.md', { 
    as: 'raw', 
    eager: true 
  });
  const fullLink = `/public${link}.md`;
  const content = markdownFiles[fullLink].split('---')[2];
  const metadata = parseFrontMatter(markdownFiles[fullLink].split('---')[1]);
  return {title: metadata.title, content: content, date: new Date(metadata.date)};
}

function parseFrontMatter(frontMatter: string) {
  const metadata: Record<string, string> = {};
  const lines = frontMatter.trim().split('\n');
  
  lines.forEach(line => {
    const [key, value] = line.split(':').map(s => s.trim());
    if (key && value) {
      metadata[key] = value;
    }
  });
  
  return metadata;
}
