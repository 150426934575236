import { useState } from "react";
import { loadMarkdownContent } from "../utils/markdownLoader";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loadMarkdownFiles } from "../utils/markdownLoader";
import { ContentType } from "../utils/content";
import { MarkdownRenderer } from "./MarkdownRenderer";

// Create a new component for markdown content
export function MarkdownContent() {
  const { title } = useParams();
  const [content, setContent] = useState<{title: string, content: string, date: Date}>({title: "", content: "", date: new Date()});
  const go = useNavigate();

  useEffect(() => {
    async function loadContent() {
      const markdownContent = await loadMarkdownFiles();
      console.log({ title, markdownContent });
      const contentItem = markdownContent.find(
        (c) =>
          c.type === ContentType.MARKDOWN && c.link === `/articles/${title}`
      );
      console.log({ contentItem });
      if (contentItem?.link) {
        const content = await loadMarkdownContent(contentItem.link);
        setContent(content);
      }
    }
    loadContent();
    // Find the matching content and load its markdown
  }, [title]);

  return (
    <div className="w-full text-left">
      <button onClick={() => go("/")}></button>
      <MarkdownRenderer title={content.title} content={content.content} date={content.date} />
    </div>
  );
}