import { FaTwitter } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaMedium } from "react-icons/fa";
import { ContentType } from "../utils/content";
import { Content } from "../utils/content";

export  function LinkComponent({ data }: { data: Content }) {
  const Icon =
    data.type === ContentType.MEDIUM
      ? FaMedium
      : data.type === ContentType.YOUTUBE
      ? FaYoutube
      : data.type === ContentType.MIRROR
      ? Mirror
      : data.type === ContentType.MARKDOWN
      ? FaFileAlt
      : FaTwitter;
  return (
    <a
      className="flex flex-col gap-1 p-3 border-stone-800 hover:bg-white/30 hover:scale-105 transition-all"
      href={data.link}
      target={data.type === ContentType.MARKDOWN ? "_self" : "_blank"}
      rel="noreferrer"
    >
      <span className="w-4/5 text-left font-serif text-sm">{data.name}</span>
      <div className="flex gap-1 items-center">
        <Icon className="text-xl" color="#413736" />
        <p className="text-xs text-slate-600">
          {months[data.date.getMonth()]} {data.date.getFullYear()}
        </p>
      </div>
    </a>
  );
}

const Mirror = () => {
  const width = 14;
  const height = 18;
  const radius = 7;

  // Define the path
  const pathData = `
    M${radius},0
    h${width - 2 * radius} 
    a${radius},${radius} 0 0 1 ${radius},${radius} 
    v${height - radius} 
    h${-width} 
    v${-height + radius} 
    a${radius},${radius} 0 0 1 ${radius},${-radius}
    Z`;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path d={pathData} fill="black" />
    </svg>
  );
};



const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

