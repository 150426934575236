import { FaGithub } from "react-icons/fa";
import { FaMedium, FaTwitter } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import pfp from "../pfp.png";
import { useNavigate } from "react-router-dom";

export const Header = ({showSocials} : {showSocials : boolean}) => {
    const navigate = useNavigate();
  return (
    <>
         <div className="flex items-center justify-center gap-8">
          <a href="https://twitter.com/0xhank" target="_blank" rel="noreferrer">
            <img
              src={pfp}
              className="h-24 events-none hover:scale-105 transition-all"
              alt="logo"
            />
          </a>
            <div className="flex flex-col gap-2 items-center">
              <span className="text-5xl font-mono bold">hank</span>
              {!showSocials && (
                <button className="text-sm text-stone-600 hover:scale-105 p-1 hover:bg-white/50 transition-all" onClick={() => navigate("/")}>
                    go home
                </button>
              )}
          {showSocials && (
              <div className="flex flex-row items-center gap-3 h-8">
                <a
                  className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                  href="https://github.com/0xhank"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub color="#413736" />
                </a>

                <a
                  className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                  href="https://twitter.com/0xhank"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter color="#413736" />
                </a>

                <a
                  className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                  href="https://medium.com/@0xhank"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaMedium color="#413736" />
                </a>

                <a
                  className="flex items-center justify-center h-3 text-stone-800 text-2xl hover:scale-105 transition-all"
                  href="mailto:zeroxhank@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaEnvelope color="#413736" />
                </a>
              </div>
            )}
          </div>
        </div>
          {showSocials && (
        <div className="flex flex-col gap-2">
          <a
            href="https://www.primodium.com/"
            className="hover:scale-105 transition-all"
          >
            Building Primodium
          </a>
          <div className="hidden md:flex text-xs flex-col md:flex-row px-2 md:gap-2 italic text-stone-600">
            <p className="not-italic">prev</p>
            <a
              href="https://www.projectawakening.io/"
              className="hover:scale-105 transition-all"
            >
              CCP Games
            </a>
            <hr className="h-full w-[1px] bg-stone-600" />
            <a
              href="https://darkseas.me/"
              className="hover:scale-105 transition-all"
            >
              Dark Seas
            </a>
            <hr className="h-full w-[1px] bg-stone-600" />
            <a
              href="https://0xparc.org/blog/aw-residency"
              className="hover:scale-105 transition-all inline"
            >
              0xPARC AW Resident
            </a>
            <hr className="h-full w-[1px] bg-stone-600" />
            <a
              href="http://www.dfdao.xyz/"
              className="hover:scale-105 transition-all inline"
            >
              dfdao
            </a>
          </div>
        </div>
      )}
    </>
  );
};

