export enum ContentType {
  MEDIUM,
  YOUTUBE,
  TWITTER,
  MIRROR,
  MARKDOWN
}

export type Content = {
  name: string;
  link: string;
  date: Date;
  type: ContentType;
};

export const myContent: Content[] = [
  {
    name: "Wondering What a Blockchain is? Here’s a Simple Technical Introduction.",
    link: "https://medium.com/@0xhank/wondering-what-a-blockchain-is-heres-a-simple-technical-introduction-b1a845f3deba",
    date: new Date("November 25, 2021"),
    type: ContentType.MEDIUM,
  },
  {
    name: "Lessons From my First Blockchain Dapp",
    link: "https://medium.com/@0xhank/lessons-from-my-first-blockchain-dapp-c5c61be723fb",
    date: new Date("December 18, 2021"),
    type: ContentType.MEDIUM,
  },
  {
    name: "9 Hidden Information Mechanics and How to Port them into Blockchain Games",
    link: "https://medium.com/@0xhank/9-hidden-information-mechanics-and-how-to-port-them-into-blockchain-games-2d862f0e16ff",
    date: new Date("January 10, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "Simple Blockchain Card Game: War",
    link: "https://medium.com/@0xhank/simple-blockchain-card-game-war-627300610234",
    date: new Date("January 18, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "It's Time for Web3 Games to Mature from Play2Earn to Play2Build",
    link: "https://medium.com/@0xhank/its-time-for-web3-games-to-mature-from-play2earn-to-play2build-51c147410ab9",
    date: new Date("February 17, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "The Junk Wars Saga",
    link: "https://medium.com/dfdao/the-junk-wars-saga-part-i-origins-dfc0e39d0256",
    date: new Date("March 11, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "Envisioning a Web3 Gaming Platform",
    link: "https://medium.com/@0xhank/envisioning-a-web3-gaming-platform-c6ecb1132433",
    date: new Date("April 18, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "Exploring Player Sovereignty through Dark Forest Arena",
    link: "https://medium.com/dfdao/exploring-player-sovereignty-through-dark-forest-arena-151313aa3b25",
    date: new Date("September 14, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "Axie Infinity Democratic Rebalancing",
    link: "https://twitter.com/0xhank/status/1479930898946666498",
    date: new Date("January 8, 2022"),
    type: ContentType.TWITTER,
  },
  {
    name: "Ludens, Jiho and the state of Play to Earn",
    link: "https://twitter.com/0xhank/status/1482839872599638022",
    date: new Date("January 16, 2022"),
    type: ContentType.TWITTER,
  },
  {
    name: "The Diamond Standard, Explained",
    link: "https://twitter.com/0xhank/status/1508191204198039552",
    date: new Date("March 27, 2022"),
    type: ContentType.TWITTER,
  },
  {
    name: "Capturing Value in Blockchain Games",
    link: "https://twitter.com/0xhank/status/1538737564714160128",
    date: new Date("June 20, 2022"),
    type: ContentType.TWITTER,
  },
  {
    name: "Recapping Dark Forest Grand Prix",
    link: "https://twitter.com/0xhank/status/1541775740923944961",
    date: new Date("June 28, 2022"),
    type: ContentType.TWITTER,
  },
  {
    name: "Ticking Chain: Making the Game of Life on a Blockchain",
    link: "https://twitter.com/0xhank/status/1551028225366695936",
    date: new Date("July 24, 2022"),
    type: ContentType.TWITTER,
  },
  {
    name: "How to mod a blockchain game: an interview with Frontier Gaming Watch ",
    link: "https://frontiergamingwatch.substack.com/p/dark-forest-dao-games-as-mediums?utm_source=twitter&sd=pf",
    date: new Date("July 26, 2022"),
    type: ContentType.MEDIUM,
  },
  {
    name: "Increasing Dark Forest Arena accessibility with Lightforest",
    link: "https://twitter.com/0xhank/status/1562462032838152193",
    date: new Date("August 24, 2022"),
    type: ContentType.TWITTER,
  },

  {
    name: "Dynamic Worlds (or, building games by consensus)",
    link: "https://www.youtube.com/embed/cWrSpTMpx4E&t=7847s",
    date: new Date("October 19, 2022"),
    type: ContentType.YOUTUBE,
  },
  {
    name: "Dark Seas (AW Residency '22 Demo Day)",
    link: "https://www.youtube.com/embed/o5q7r7rrco0&list=PLNK7oFq6eaEwzgXeL6omjG06q6TpP8_Jd&index=9",
    date: new Date("December 5, 2022"),
    type: ContentType.YOUTUBE,
  },
  {
    name: "Introducing Dark Seas",
    link: "https://medium.com/@0xhank/avast-welcome-to-dark-seas-fa4e9ccb3c0f",
    date: new Date("March 10, 2023"),
    type: ContentType.MEDIUM,
  },
  {
    name: "What I want to build",
    link: "https://medium.com/@0xhank/what-do-you-want-to-build-most-5dc802a168d6",
    date: new Date("June 13, 2023"),
    type: ContentType.MEDIUM,
  },
  {
    name: "On Maintaining Motivation and Productivity",
    link: "https://medium.com/@0xhank/what-do-you-want-to-build-most-5dc802a168d6",
    date: new Date("July 2, 2023"),
    type: ContentType.MEDIUM,
  },
  {
    name: "The On Chain Gaming Thesis",
    link: "https://mirror.xyz/matchboxdao.eth/Sw-wQOObUKO2v5Yr6e4--OYSxq-zsEaHo0Aqh6SpWas",
    date: new Date("May 17, 2022"),
    type: ContentType.MIRROR,
  },
];
