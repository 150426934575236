import Markdown from "react-markdown";

interface MarkdownRendererProps {
  title: string;
  content: string;
  date: Date;
}

export function MarkdownRenderer({
  title,
  content,
  date,
}: MarkdownRendererProps) {
  return (
    <div className="flex flex-col w-full">
      <h1 className="text-lg font-bold">{title}</h1>
      <p>{date.toLocaleDateString()}</p>
      <hr className="border-t-1 border-stone-500 my-2 mr-6" />
      <Markdown className="prose prose-stone max-w-none prose-headings:font-bold prose-a:text-blue-600 text-left">
        {content}
      </Markdown>
    </div>
  );
}
