import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MarkdownContent } from "./components/MarkdownComponent";
import { Home } from "./components/Home";
import { ContentWrapper } from "./components/ContentWrapper";

// Update the main App component to use Router
function App() {
  return (
    <BrowserRouter>
      <ContentWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/articles/:title" element={<MarkdownContent />} />
        </Routes>
      </ContentWrapper>
    </BrowserRouter>
  );
}

export default App;
