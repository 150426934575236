import { useEffect, useRef } from "react";

import { useState } from "react";
import { Content, myContent } from "../utils/content";
import { loadMarkdownFiles } from "../utils/markdownLoader";
import { LinkComponent } from "./LinkComponent";

export const Home = () => {

  const [allContent, setAllContent] = useState<Content[]>(myContent);

  useEffect(() => {
    async function loadContent() {
      const markdownContent = await loadMarkdownFiles();
      console.log(markdownContent);
      setAllContent([...myContent, ...markdownContent]);
    }
    loadContent();
  }, []);


  return (

      <div className="w-full md:w-7/8 m-auto mt-2 grid grid-cols-1 sm:grid-cols-2 gap-1 lg:grid-cols-3 overflow-y-auto overflow-x-hidden custom-scrollbar p-2">
        {allContent
          .sort((a, b) => (a.date < b.date ? 1 : -1))
            .map((content) => (
                <LinkComponent key={content.name} data={content} />
            ))
        }
      </div>
  );
}
