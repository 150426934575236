import { useEffect, useRef } from "react";

import { useState } from "react";
import { myContent } from "../utils/content";
import { loadMarkdownFiles } from "../utils/markdownLoader";
import { Header } from "./Header";
import { useLocation } from "react-router-dom";

export const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const isHome = useLocation().pathname === "/";
    
  return (
    <div className="w-full h-full bg-slate-700 flex md:justify-center md:items-center overflow-hidden h-screen">
      <div className="flex flex-col text-center items-center m-4 md:m-16 p-8 md:p-10 gap-2 font-mono text-stone-800 bg-slate-200 md:w-3/4 max-w-[1000px] md:h-3/4 shadow-lg">
        <Header showSocials={isHome} />
        <div
          ref={contentRef}
          className="overflow-y-auto w-full h-full custom-scrollbar"
        >
          {children}
        </div>
      </div>
    </div>
  );
};
